import React, {useState, useEffect} from 'react';


var completeScan = null;

function ScannerControl(props) {
  const displayName = props.scanner.name;
  const scannerName = props.scanner.scannerName;
  const headThingName = props.scanner.headThingName;

  const iotConnection = props.iotConnection;
  const iotConnected = props.iotConnected;

  completeScan = props.completeScan;

  const [scannerStatus, setScannerStatus] = useState("Disconnected");
  const [headStatus, setHeadStatus] = useState("Disconnected");

  // Subscribe to topics when thing choices change
  useEffect(() => {
    if (!iotConnected)
      return;

    console.log("Connecting to scanner: " + scannerName);
    
    // Head UI connection
    const scannerTopic = `SS20/${scannerName}`;
    iotConnection.subscribe(scannerTopic + '/state', {}, () => {
      iotConnection.publish(scannerTopic + '/get', "{}");
    });

    iotConnection.on('message', (topic, payload) => {
      if (topic === scannerTopic + '/state') {
        try {
          const report = JSON.parse(payload);
          setScannerStatus(report.status);

          if (report.status === 'MultiscanMeasure Completed') {
            setTimeout(completeScan, 10000);
          }
        } catch (ex) {
          console.log("Received invalid payload");
        }
      }
    });

    // Scanner connection
    iotConnection.register(headThingName, {}, () => {
      iotConnection.get(headThingName);
    });

    iotConnection.on('status', (scannerName, stat, clientToken, stateObject) => {
      if (stateObject.state && stateObject.state.reported) {
        setHeadStatus(stateObject.state.reported.page);
      }
    });

    iotConnection.on('foreignStateChange', (thingName, operation, stateObject) => {
      if (stateObject.state && stateObject.state.reported) {
        setHeadStatus(stateObject.state.reported.page);
      }
    });

    return () => {
      console.log("Disconnecting from: " + scannerName);
      iotConnection.end();
    };
  }, [scannerName, headThingName, iotConnection, iotConnected]);

  return (
    <section className="ScannerControl">
      <h2>Scanning</h2>
      <div>Scanner: {displayName}</div>
      <div>Scanner Status: {scannerStatus}</div>
      <div>Head Status: {headStatus}</div>
      <div className="actions">
        <button disabled={!iotConnected} onClick={async () => {
          const customer = await props.provisionCustomer(props.customer);

          console.log("Priming scanner for user: " + customer.userId)
          iotConnection.update(headThingName, {state: {desired: {
            page: "Start",
            userName: customer.name,
            userId: customer.userId
          }}});
        }}>Start</button>
      </div>
    </section>
  )
}

export default ScannerControl;
