import React, { useState, useEffect } from 'react';
import _ from 'lodash';


function Recommendation(props) {
  const product = props.recommendation.product;
  const shapes = props.recommendation.shapes;
  const shapeCode = props.recommendation.shapeCode;

  let garmentMeasurementString = props.recommendation.garmentMeasurementString;
  if (typeof garmentMeasurementString === "string")
    garmentMeasurementString = garmentMeasurementString.split('<br/>').join('\n');

  function onSelectChange(e) {
    props.changeSelection(props.recommendation.categoryProductCode,
                          e.target.checked
    );
  }

  function onPrefChange(e) {
    props.changePrefSelection(props.recommendation.preferenceCode,
                              shapes[e.target.selectedIndex].preferenceSelectionCode)
  }

  return (
    <tr className="Recommendation">
      <td><input type="checkbox" checked={props.recommendation.selected} onChange={onSelectChange}/></td>
      <td>{product}</td>
      <td>
        <select value={shapeCode}
                onChange={onPrefChange}>
          {shapes.map((shape, index) =>
            <option key={index}
                    value={shape.shapeCode}>
              {shape.shape}
            </option>
          )}
        </select>
      </td>
      <td>
        <div className="RecommendationFilter">
          {(props.recommendation.shapeCode) ? props.recommendation.shape : ''}
          <br/>
          {props.recommendation.size}
          <br/>
          {garmentMeasurementString}
        </div>
      </td>
    </tr>
  )
}

function RecommendationTable(props) {
  const products = props.products;
  const selections = props.selections;
  const setSelections = props.setSelections;
  const prefSelections = props.prefSelections;
  const customerRecommendations = props.customerRecommendations;

  const tableRecommendations = products.map(product => {
    const shapeCode = product.shapes.find(shape =>
      shape.preferenceSelectionCode === prefSelections[product.preferenceCode]
    ).shapeCode;

    return {
      selected: _.some(selections, product.categoryProductCode),
      ...product,
      ...(customerRecommendations.find(crec =>
        _.isEqual(crec.categoryProductCode, product.categoryProductCode)
      )),
      shapeCode: shapeCode
    };
  });

  function changeSelection(product, isSelected) {
    if (isSelected)
      setSelections(selections.concat(product));
    else
      setSelections(selections.filter(p => !_.isEqual(p, product)))
  }

  const allCheckIsChecked = tableRecommendations.filter(tr => tr.selected === false).length === 0;
  function allCheckChanged(e) {
    const checked = e.target.checked;
    if (checked)
      setSelections(tableRecommendations.map(tr => tr.categoryProductCode));
    else
      setSelections([]);
  }

  return (
    <table className="RecommendationTable">
      <thead>
        <tr>
          <th>
            <input type="checkbox"
                    checked={allCheckIsChecked}
                    onChange={allCheckChanged}/>
            </th>
          <th>Product Name</th>
          <th>Fit Preference</th>
          <th>Fit Recomendation</th>
        </tr>
      </thead>
      <tbody>
        {tableRecommendations.map((trec, index) =>
          <Recommendation key={index}
                          recommendation={trec}
                          changeSelection={changeSelection}
                          changePrefSelection={props.changePrefSelection} />
        )}
      </tbody>
    </table>
  )
}

function Fit(props) {
  const products = props.products;
  const updateSelections = props.updateSelections;

  const customerRecommendations = props.customer.recommendations ? props.customer.recommendations : [];

  const [filterByLocation, setFilterByLocation] = useState(true);
  const locationProductList = props.location.products;
  const displayProducts = (filterByLocation)
                            ? _.intersectionWith(products, locationProductList, (p, n) =>
                              _.isEqual(p.categoryProductCode, n)
                            ) : products;

  const [selections, setSelections] = useState(products.map(p => p.categoryProductCode));

  // Product and Preference selections
  const defaultPrefSelections = products.reduce((obj, product) => {
    obj[product.preferenceCode] = product.shapes[0].preferenceSelectionCode;
    return obj
  }, {});
  const [prefSelections, setPrefSelections] = useState(defaultPrefSelections);
  function changePrefSelection(pref, prefSelection){
    setPrefSelections({...prefSelections, [pref]: prefSelection});
  }

  useEffect(() => {
    if (filterByLocation)
      setSelections(s => _.intersectionWith(s, locationProductList, _.isEqual))
  }, [filterByLocation, locationProductList, setSelections]);

  return (
    <section className="FitRecommendations">
      <h2>Fit Recommendation</h2>

      <div className="ProductFilter">
        <label>Show only products at this location</label>
        <input type="checkbox"
               checked={filterByLocation}
               onChange={(e) => {setFilterByLocation(e.target.checked)} } />
      </div>

      <RecommendationTable products={displayProducts}
                           selections={selections}
                           setSelections={setSelections}
                           prefSelections={prefSelections}
                           changePrefSelection={changePrefSelection}
                           customerRecommendations={customerRecommendations} />

      <div className="actions">
        <button onClick={() => updateSelections(selections, prefSelections)}>
          Request Fit Preferences
        </button>
      </div>
    </section>
  );
}

export default Fit;
