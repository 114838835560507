import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';

import API from '@aws-amplify/api';

import './App.css';
import Info from './Info.js';
import Fit from './Fit.js';
import ScannerControl from './ScannerControl.js';

import IoT, {IoTConnection} from './IoT.js'
import {collectProducts, getGppLogicEngineResults, collectCustomerRecommendations} from './Gpp.js';
import LocationSelections, {InitialLocSels} from './LocationSelections.js';

import sample from './sample.json';


async function createCustomer3did(customer, orgId) {
  try {
    console.log("Create or Update 3did User");
    const path = '/users/' + customer.customerId.toString();
    const result = await API.put('org-service-api', path, {
      headers: {
        'org-id': orgId
      },
      body: {
        name: {
          firstName: customer.name
        },
        gender: customer.gender
      }
    });
    return result.userId;
  }
  catch (ex) {
    console.error("Unable to retrieve 3did user id: " + ex.toString());
    return -1;
  }
}

var scanCompleted = false;
var gppRequest = null;

// Get input provided from url query string
const UrlParams = Array.from(new URLSearchParams(window.location.search))
  .reduce((object, [key, value]) => {object[key] = value; return object}, {});

function App(props) {
  const orgId = props.orgId;

  // Location Selections (Location, Scanner, FitPreferenceStation)
  const [locationSelections, setLocationSelections] = useState(InitialLocSels);
  const setLocSels = useCallback(value => {
    setLocationSelections((locationSelections) => {
      return {
        ...locationSelections,
        locSels: value
      }
    })
  }, [setLocationSelections]);

  useEffect(() => {
    LocationSelections(UrlParams).then((e) => {
      setLocationSelections({
        locSels: e.defaultSelections,
        locSelChoices: e.locationSelectionChoices(e.defaultSelections),
        handleLocSelChange: e.locationSelectionChangeHandler(e.defaultSelections, setLocSels)
      });
    });
  }, [setLocSels]);

  // Customer information
  const providedCustomer = _.pick(UrlParams, [
    "customerId",
    "name",
    "gender"
  ]);
  const [customer, setCustomer] = useState(providedCustomer);

  async function provisionCustomer(customer) {
    const userId = await createCustomer3did(customer, orgId);
    const newCustomer = {userId: userId, ...customer};
    setCustomer(newCustomer);
    return newCustomer;
  }

  // GPP Result data and cache management
  async function pollCustomerRecommendations(forceExecution=false) {
    if (scanCompleted) {
      const gppResults = await getGppLogicEngineResults(orgId, customer.customerId, gppRequest, forceExecution);
      if (gppResults.status === "FAILED") {
        const cRecs = gppRequest.CategoryProductCodeArray.map(pc => {
          return {
            "categoryProductCode": pc,
            "shape": "No matching results",
            "shapeCode": null,
            "size": "No matching results",
            "garmentMeasurementString": "Failed to get fit recommendation"
          }
        });
        setCustomer({...customer, recommendations: cRecs});
      } else if (gppResults.status === "SUCCEEDED") {
        const cRecs = collectCustomerRecommendations(gppResults);
        setCustomer({...customer, recommendations: cRecs});
      } else {
        setTimeout(pollCustomerRecommendations, 2000);
      }
    }
  }

  async function completeScan() {
    scanCompleted = true;
    pollCustomerRecommendations(true)
  }

  // Preference Selections (GPP request)
  const products = collectProducts(sample.brandPreferences);

  if (gppRequest === null)
    gppRequest = {
      "FilteredResults": 1,
      "BrandCode": "ALT001",
      "CategoryProductCodeArray": products.map(p => p.categoryProductCode),
      "PreferenceSelectionCodeArray": products.map(p => p.shapes[0].preferenceSelectionCode)
    };
  function updateSelections(selections, prefSelections) {
    gppRequest = {
      "FilteredResults": 1,
      "BrandCode": "ALT001",
      "CategoryProductCodeArray": selections,
      "PreferenceSelectionCodeArray": Object.values(prefSelections)
    };
    pollCustomerRecommendations(true);
  }

  // Connect IoT when authorized
  const [iotConnected, setIotConnected] = useState(false);
  useEffect(() => {
    IoT.connect(() => setIotConnected(true));
    return () => {IoT.disconnect(() => setIotConnected(false))};
  }, [orgId]);

  return (
    <div className="App">
      <Info locations={locationSelections.locSelChoices.locationChoices}
            fitPrefStations={locationSelections.locSelChoices.fitPrefStationChoices}
            scanners={locationSelections.locSelChoices.scannerChoices}
            state={locationSelections.locSels}
            onChange={locationSelections.handleLocSelChange}
            customer={customer} />
      <Fit location={locationSelections.locSels.location}
           products={products}
           customer={customer}
           updateSelections={updateSelections}/>
      <ScannerControl scanner={locationSelections.locSels.scanner}
                      customer={customer}
                      iotConnection={IoTConnection}
                      iotConnected={iotConnected}
                      provisionCustomer={provisionCustomer}
                      completeScan={completeScan}/>
    </div>
  );
}

export default App;
