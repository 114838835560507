import Auth from '@aws-amplify/auth';
import AwsIot from 'aws-iot-device-sdk';

import config from './config.json';


export var IoTConnection;

const IoT = {
    getIotConnection: () => IoTConnection,

    connect: function(callback) {
        Auth.currentCredentials().then(credentials => {
            IoTConnection = AwsIot.thingShadow({
                protocol: "wss",
                host: config.IoT.endpoint,
                accessKeyId: credentials.accessKeyId,
                secretKey: credentials.secretAccessKey,
                sessionToken: credentials.sessionToken,
                clientId: `AssociateUI_${credentials.accessKeyId}_${Date.now().toString}`
            })
            .on('connect', () => {
                console.log("Connected to IoT");
                callback();
            });
        })
    },

    disconnect: function(callback) {
        console.log("Disconnecting from IoT");
        IoTConnection.end();

        callback();
    }
};

export default IoT;